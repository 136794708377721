<template>
	<div>
		<div class="wrapper">
			<v-container
				class="pa-0"
				fluid
			>
				<v-row
					v-if="loading"
					class="ma-0 loading_area"
				>
					<v-col
						class="pa-0"
						cols="12"
					>
						<CommonLoaderCircle01 />
					</v-col>
				</v-row>
				<v-row
					v-else
					class="ma-0"
				>
					<v-col
						md="8"
						sm="12"
						class="pa-0 px-0 px-md-5 mt-0 mt-md-10 contents_table layout_align"
					>
						<!-- 비주얼영역 -->
						<v-img
							eager
							max-width="829"
							class="bisual_area mb-7"
							:src="specialItem.image"
						/>
						<div class="px-4 px-md-0">
							<!-- 제목 -->
							<!-- 제목 -->
							<HomeHousewarmingHeader
								:items="specialItem"
								:type-title="common_title"
							/>

							<!-- 컨텐츠 -->
							<v-col
								class="body pa-0 pt-7 pt-md-11"
								cols="12"
								align="center"
							>
								<v-col class="pa-0 editor_content">
									<v-row class="ma-0">
										<v-col
											class="pa-0"
											cols="12"
											md="12"
										>
											<!-- 업체 정보 -->
											<HomeHousewarmingInfoBoxStore :items="specialItem" />
										</v-col>
										<v-col
											class="pa-0"
											cols="12"
											md="12"
										>
											<!-- 사례 정보 -->
											<HomeHousewarmingInfoBox :items="specialItem" />
										</v-col>
									</v-row>

									<!-- 비주얼영역 -->
									<!-- <v-img
									eager
									:src="specialItem.image"
								/> -->

									<!-- 키워드 태그영역 -->
									<HomeProductDetailTags
										v-if="specialItem.tags"
										:items="specialItem.tags"
										class="tag_area bg_tr"
									/>

									<!-- <CommonButtonsButton02
									v-if="specialItem.corporate_no !== '1378102333'"
									name="맞춤견적 신청"
									class-name="btn_large mb-16"
									color="#42883d"
									@click="goToInquire"
								/> -->

									<!-- 에디터 영역 입니다 -->
									<v-card
										v-if="specialItem.description"
										width="100%"
										min-height="500"
										elevation="0"
										class="ma-0 pa-0 mb-5"
									>
										<CommonEditorQuillViewerTile
											class="py-10"
											:html="specialItem.description"
										/>
									</v-card>
									<!-- e : [확인사항] 에디터 편집영역 입니다 -->
								</v-col>
							</v-col>
						</div>
					</v-col>
					<v-col
						v-if="getWidth > 960"
						md="4"
						sm="0"
						class="pa-0 pl-lg-10 pl-5 pr-5 pr-md-5 mt-7 mt-md-10 contents_table layout_align"
					>
						<div
							class="fixed-tabs-bar"
							:class="scrollUp ? 'up' : ''"
						>
							<HomeInteriorPriceDetailinquireCommonForm
								:items="specialItem"
								:cont-item="contProduct"
								:cont-interior="contInterior"
								:scroll-up="scrollUp"
							/>
						</div>
					</v-col>
				</v-row>
			</v-container>
			<!--모바일 팝업-->
			<div
				v-if="getWidthMobile"
				class=""
			>
				<div class="all_view">
					<v-img
						eager
						alt="간편상담"
						height="22"
						:src="require(`@/assets/img/estimate/ico_rapid_consult.svg`)"
					/>
					<span
						class="my-0"
						@click="openConsult"
					>
						간편상담
					</span>
				</div>
			</div>
			<div
				v-if="getWidthMobile"
				class="mo_consultWrap"
			>
				<div
					v-if="popupDialog"
					class="popWrap"
				>
					<!-- <p class="">asdf <span @click="closeConsult">닫기</span></p> -->
					<div class="vlistitem_dumy">
						<v-row class="px-4">
							<!-- <v-list-item-title>간편상담 신청하기</v-list-item-title> -->
							<v-col class="contAsk">간편상담 신청하기</v-col>
							<v-col
								class="pa-0 pr-0"
								align-self="center"
								style="text-align: right"
							>
								<v-btn
									icon
									text
									@click="closeConsult"
								>
									<v-icon color="#666">mdi-window-close</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</div>
					<v-divider></v-divider>
					<HomeInteriorPriceDetailinquireCommonForm
						:items="specialItem"
						:cont-item="contProduct"
						:cont-interior="contInterior"
						:mobile-state="mobileState"
					/>
				</div>
			</div>
		</div>
		<div class="recommendZone">
			<div class="wrapper">
				<v-row>
					<!-- 전문가 집들이 -->
					<v-col
						v-if="specialShortItems"
						cols="12"
						align="center"
						class="pa-0"
					>
						<v-card class="feature_tit bc_tr mb-8">
							<p>전문가 집들이</p>
						</v-card>
						<v-col
							cols="11"
							md="12"
							class="pa-0"
						>
							<div class="card_align">
								<CommonCardMenuGroup05
									:menu-type="'special'"
									:card-menu="specialShortItems"
									@submit="onClickPushLink"
								/>
							</div>
						</v-col>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import ad_kakao from '@/mixins/ad/kakao'
import ad_naver from '@/mixins/ad/naver'
import ad_mobion from '@/mixins/ad/mobion'

export default {
	name: 'HousewarmingSpecialDetail',
	metaInfo() {
		return {
			title: `${this.specialItem.title} | 동화자연마루`,
			meta: [
				{
					vmid: 'title',
					property: 'og:title',
					title: `${this.specialItem.title} | 동화자연마루`,
				},
				{
					vmid: 'description',
					property: 'og:description',
					content: '동화자연마루를 활용한 전문가 시공사례를 확인해보세요.',
				},
				{
					vmid: 'url',
					property: 'og:url',
					content: `${process.env.VUE_APP_BASIC_SERVER}/housewarming/specialist/${this.getQuery}`,
				},
				{
					vmid: 'og:image',
					property: 'og:image',
					content: `${this.specialItem.image}`,
				},
			],
		}
	},
	mixins: [common, ad_kakao, ad_naver, ad_mobion],
	data: () => ({
		specialItem: {},
		house3dShortItems: [],
		specialShortItems: [],
		loading: false,
		contProduct: null,
		contInterior: null,
		scrollUp: true,
		popupDialog: false,
		mobileState: false,
		getWidthMobile: false,
	}),
	computed: {
		...mapGetters([
			// 제품 item
			'HOME_GET_GET_PRODUCT_ITEMS',
		]),
		getQuery() {
			return this.$route.params.id
		},
		getWidth() {
			return this.APP_GET_APP_WIDTH
		},
	},
	watch: {
		getQuery() {
			this.getData()
		},
		getWidth(val) {
			this.mobchange(val)
			if (val > 960) {
				this.handleComputedDataChange(false, 'popstate')
			} else {
				//this.handleComputedDataChange(true, 'popstate')
			}
		},
		popupDialog(val) {
			this.handleComputedDataChange(val, 'popstate')
		},
	},
	created() {
		this.getData()
		window.addEventListener('scroll', this.handleScroll)
		this.getToInquire()
	},
	deactivated() {
		window.removeEventListener('scroll', this.handleScroll)
		this.handleComputedDataChange(false, 'popstate')
	},
	mounted() {
		// ad_kakao page
		this.ad_kakao_pageScript('specialist_detail')
		// ad_naver
		this.ad_naver_changeScript(4, 1)
		// ad_mobion page
		this.ad_mobion_page_convertion('page_view_specialist_detail')
		this.mobchange(this.getWidth)
	},
	destroyed() {
		this.handleComputedDataChange(false, 'popstate')
	},
	methods: {
		...mapMutations(['HOME_MU_INTERIOR_SELECT_PRODUCT', 'HOME_MU_INTERIOR_SELECT_INTERIOR']),
		...mapActions([
			'HOME_ACT_GET_PRODUCT_ITEMS',
			'HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_ITEM',

			'HOME_ACT_HOUSEWARMING_SPECIAL',
			'HOME_ACT_HOUSEWARMING_HOUSE_3D',
		]),
		async getData() {
			this.loading = true
			// 인테리어점 정보 등록
			// const interiorGetData = await this.HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_ITEM(this.specialItem.corporate_no)
			// let interior = interiorGetData[0]

			// if (interior) {
			// 	// 추가입력사항
			// 	interior.type = '전문가집들이관리'
			// 	interior.buildItems = interior.buildtype.map(x => x.code_name)
			// 	this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.specialItem)
			// }

			// 3D집들이 상세 정보
			await this.HOME_ACT_HOUSEWARMING_SPECIAL({ method: 'detail', query: this.getQuery })
				.then(res => {
					this.parseData(res)
					// 추가입력사항
					let product = res

					product[0].type = '전문가집들이관리'
					product[0].contents = {
						name: this.specialItem.title,
						id: this.getQuery,
					}
					console.log('product', product)
					this.contProduct = product[0]

					// 인테리어점 정보 등록
					this.getThisInterior(this.specialItem.corporate_no)
					// let interior = this.contInterior

					// console.log('this.contInterior', this.contInterior)

					// if (interior) {
					// 	// 추가입력사항
					// 	interior.type = '전문가집들이관리'
					// 	interior.buildItems = interior.buildtype.map(x => x.code_name)
					// 	this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.specialItem)
					// }
				})
				.catch(() => {
					this.loading = false
				})
			// 3D집들이 간략정보
			await this.HOME_ACT_HOUSEWARMING_HOUSE_3D({ method: '3D_pop', query: this.getQuery })
				.then(res => {
					this.house3dShortItems = res
				})
				.catch(() => {
					this.loading = false
				})
			// 전문가집들이 간략정보
			await this.HOME_ACT_HOUSEWARMING_SPECIAL({ method: 'special_pop', query: this.getQuery })
				.then(res => {
					this.specialShortItems = res
					this.loading = false
				})
				.catch(() => {
					this.loading = false
				})
		},
		parseData(data) {
			console.log('HOME_ACT_HOUSEWARMING_SPECIAL', data)
			let parser = JSON.parse(JSON.stringify(data[0]))

			parser.image = parser.image_url ? parser.image_url : require('@/assets/img/etc/img_house.jpg')
			parser.is_img = parser.image_url?.length

			console.log('parser', parser)

			this.specialItem = parser
		},
		async getThisInterior(val) {
			const res = await this.HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_ITEM(val)
			this.contInterior = res
		},
		async goToInquire() {
			// // 제품 정보 등록
			// // 메인 제품 list에서 선택 상품 찾기
			// if (this.HOME_GET_GET_PRODUCT_ITEMS.length === 0) await this.HOME_ACT_GET_PRODUCT_ITEMS({})
			// let product = this.HOME_GET_GET_PRODUCT_ITEMS.find(item => item.goods_code === this.specialItem.goods_code)
			// if (product) {
			// 	// 추가입력사항
			// 	product.type = '전문가집들이관리'
			// 	product.contents = {
			// 		name: this.specialItem.title,
			// 		id: this.getQuery,
			// 	}
			// 	this.HOME_MU_INTERIOR_SELECT_PRODUCT(product)
			// }
			// // 인테리어점 정보 등록
			// const interiorGetData = await this.HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_ITEM(this.specialItem.corporate_no)
			// let interior = interiorGetData[0]
			// if (interior) {
			// 	// 추가입력사항
			// 	interior.type = '전문가집들이관리'
			// 	interior.buildItems = interior.buildtype.map(x => x.code_name)
			// 	this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.specialItem)
			// }
			// // console.log('goToInquire', this.specialItem)
			// // console.log('product', product)
			// // console.log('interior', interior)
			// this.$router.push('/interior/price/inquire').catch(() => {})
		},
		async getToInquire() {
			// 제품 정보 등록
			// 메인 제품 list에서 선택 상품 찾기
			// if (this.HOME_GET_GET_PRODUCT_ITEMS.length === 0) await this.HOME_ACT_GET_PRODUCT_ITEMS({})
			// let product = this.HOME_GET_GET_PRODUCT_ITEMS.find(item => item.goods_code === this.specialItem.goods_code)

			// if (product) {
			// 	// 추가입력사항
			// 	product.type = '전문가집들이관리'
			// 	product.contents = {
			// 		name: this.specialItem.title,
			// 		id: this.getQuery,
			// 	}
			// 	this.contProduct = product
			// }

			// 인테리어점 정보 등록
			const interiorGetData = await this.HOME_ACT_INTERIOR_GET_INTERIOR_DETAIL_ITEM(this.specialItem.corporate_no)
			let interior
			if (interiorGetData.length !== 0) {
				interior = interiorGetData[0]
			}

			if (interior) {
				// 추가입력사항
				interior.type = '전문가집들이관리'
				interior.buildItems = interior.buildtype.map(x => x.code_name)
				this.HOME_MU_INTERIOR_SELECT_INTERIOR(this.specialItem)
			}
			this.contInterior = interior
		},
		onClickPushLink(menu) {
			console.log('menu', menu)
			// 기본 3d
			let link = `/housewarming/3d/detail/${menu.hw_id}`
			if (menu.link === 'special') link = `/housewarming/specialist/detail/${menu.exphw_id}`

			this.$router.push(link).catch(() => {})
		},
		handleScroll() {
			// 스크롤 방향 체크하기
			const scrollTop = window.scrollY || window.pageYOffset
			if (scrollTop > this.lastScrollTop) {
				this.scrollUp = false
			} else if (scrollTop < this.lastScrollTop) {
				this.scrollUp = true
			}
			this.lastScrollTop = scrollTop
		},
		//간편견적 모바일
		openConsult() {
			this.popupDialog = true
			this.mobileState = true
		},
		closeConsult() {
			this.popupDialog = false
			this.mobileState = false
			//if (e.target.className === 'closeBtn') this.popupDialog = false
		},
		mobchange(val) {
			if (val > 960) {
				this.getWidthMobile = false
			} else {
				this.getWidthMobile = true
			}
		},
		handleComputedDataChange(newVal, state) {
			var htmlElement = document.querySelector('html')

			if (newVal === true && state === 'popstate') {
				htmlElement.style.overflow = 'hidden'
			}
			if (newVal === false && state === 'popstate') {
				htmlElement.style.overflow = 'visible'
			}
		},
	},
}
</script>

<style scoped lang="scss">
.vlistitem_dumy {
	align-items: center;
	align-self: center;
	display: flex;
	flex-wrap: wrap;
	flex: 1 1;
	overflow: hidden;
	padding: 12px 0;
	.contAsk {
		font-weight: $fw_bold;
		font-size: $font_xl;
		color: $color_font;
	}
}

.bisual_area {
	border-radius: 16px;
	max-height: 369px;
}
.tag_area {
	margin: 0 !important;
}
// .info_box {
// 	min-height: 245px;
// }

.fixed-tabs-bar {
	position: -webkit-sticky;
	position: sticky;
	top: 90px;
	z-index: 2;
	background-color: #fff;
	&.up {
		top: 117px;
	}
}
.recommendZone {
	background: #f8f8f8;
	margin-top: 50px;
	bottom: -38px;
	position: relative;
	padding: 40px 0;
}

.popWrap {
	position: fixed;
	z-index: 100;
	height: 100%;
	top: 0px;
	width: 100%;
	background: #fff;
}
.all_view {
	display: flex;
	position: fixed;
	align-items: center;
	bottom: 101px;
	right: 20px;
	z-index: 10;
	background-color: #00ce7d;
	color: #fff;
	padding: 5px 10px;
	border-radius: 99px;
	box-shadow: 0px 2px 8px 0px #00000026;
	&:hover {
		cursor: pointer;
	}
}

@media all and (min-width: 981px) and (max-width: 1280px) {
}
@media all and (min-width: 769px) and (max-width: 980px) {
}
@media all and (min-width: 380px) and (max-width: 768px) {
	.contents_table {
		.body {
			padding: 32px 0 !important;
		}
	}
}

@media all and (max-width: 980px) {
	.bisual_area {
		border-radius: 0;
	}
}
@media all and (max-width: 380px) {
	.contents_table {
		.body {
			padding: 20px 0 !important;
		}
	}
}
</style>
